@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .stakeholder-detail h3 {
        font-weight: bold;
        padding: 10px 0px 10px 0px;
    }

    .stakeholder-detail p {
        margin-bottom: 20px;
    }

    .racimatrix .racilogo {
        width: 150px;
        margin-bottom: -70px;
    }

    .raciflow .racilegend {
        width: 100%;
    }
}